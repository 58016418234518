<template>
  <div class="login">
    <div class="login-box">
      <div class="title">
        <span v-if="loginIsShow">Login</span>
        <span v-else-if="registerIsShow">Register</span>
        <span v-else-if="forgetIsShow">Forget Password</span>
      </div>
      <el-form
        label-position="top"
        label-width="80px"
        :model="formLogin"
        v-show="loginIsShow"
        :rules="loginRules"
        ref="loginRef"
        hide-required-asterisk
      >
        <el-form-item label="Email" prop="email">
          <el-input
            v-model="formLogin.email"
            placeholder="Please input email"
          ></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password">
          <el-input
            v-model="formLogin.password"
            placeholder="Please input password"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form
        label-position="top"
        label-width="80px"
        :model="userInfo"
        v-show="registerIsShow || forgetIsShow"
        :rules="registerRules"
        ref="registerRef"
        hide-required-asterisk
      >
        <el-form-item label="Email" prop="email">
          <el-input
            v-model="userInfo.email"
            placeholder="Please input email"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Verification Code"
          class="authCode"
          prop="authCode"
        >
          <el-input
            v-model="userInfo.authCode"
            class="auth-input"
            placeholder="Code"
          ></el-input>
          <el-button
            @click.prevent=""
            class="auth-btn"
            @click="getVerifyCode(userInfo.email)"
            :loading="btnLoading"
          >Get Code
          </el-button>
        </el-form-item>
        <el-form-item label="Password" prop="prePassword">
          <el-input
            v-model="userInfo.prePassword"
            placeholder="Please input password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="Confirm password" prop="password">
          <el-input
            v-model="userInfo.password"
            placeholder="Please input password again"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" class="submit" @click="onsubmit">
        <span v-if="loginIsShow">Login</span>
        <span v-else-if="registerIsShow">Register</span>
        <span v-else-if="forgetIsShow">Submit</span>
      </el-button>
      <div class="button-box">
        <el-button
          type="text"
          class="register"
          v-if="loginIsShow || forgetIsShow"
          @click="toSignup"
        >Register
        </el-button>
        <el-button
          type="text"
          class="register"
          v-if="registerIsShow || forgetIsShow"
          @click="toLogin"
        >Login
        </el-button>
        <el-button
          type="text"
          class="forget"
          v-if="loginIsShow || registerIsShow"
          @click="toResetPassword"
        >Forget Password？
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { toResetPassword, toSignup, verifyCode, toLogin } from '../api/login';

export default {
  data() {
    let checkPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please fill in the password again'));
      } else if (value !== this.userInfo.prePassword) {
        callback(
          new Error('The two passwords are inconsistent. Please re-enter')
        );
      } else {
        callback();
      }
    };
    return {
      formLogin: {
        email: '',
        password: '',
      },
      userInfo: {
        email: '',
        authCode: '',
        prePassword: '',
        password: '',
      },
      loginRules: {
        email: [
          {
            required: true,
            message: 'Please fill in the mailbox',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please fill in the correct mailbox',
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          {
            required: true,
            message: 'Please fill in the password',
            trigger: 'blur',
          },
        ],
      },
      registerRules: {
        email: [
          {
            required: true,
            message: 'Please fill in the mailbox',
            trigger: 'blur',
          },
          // {
          //   type: "email",
          //   message: "Please fill in the correct mailbox",
          //   trigger: ["blur", "change"],
          // },
        ],
        authCode: [
          {
            required: true,
            message: 'Please fill in the verification code',
            trigger: 'blur',
          },
        ],
        prePassword: [
          {
            required: true,
            message: 'Please fill in the password',
            trigger: 'blur',
          },
        ],
        password: [
          {
            validator: checkPassword,
            trigger: 'blur',
          },
        ],
      },
      loginIsShow: true,
      registerIsShow: false,
      forgetIsShow: false,
      btnLoading: false,
    };
  },
  mounted() {
    console.log(this.$refs);
  },
  methods: {
    getVerifyCode(email) {
      const emailRegex =
        /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/;
      if (!email) {
        return this.$message({
          message: 'Please fill in the mailbox',
          type: 'error',
        });
      }
      if (!emailRegex.test(email)) {
        return this.$message({
          message: 'Please fill in the correct mailbox',
          type: 'error',
        });
      }
      this.btnLoading = true;
      setTimeout(() => (this.btnLoading = false), 10000);
      verifyCode(email).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.$message({ message: 'succeed!', type: 'success' });
        }
      });
    },
    resetPassword() {
      toResetPassword({
        code: this.userInfo.authCode,
        confirmPassword: this.userInfo.password,
        email: this.userInfo.email,
        password: this.userInfo.prePassword,
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.$message({ message: 'succeed!', type: 'success' });
          this.loginIsShow = true;
          this.registerIsShow = false;
          this.forgetIsShow = false;
          this.$refs['registerRef'].resetFields();
          this.$refs['loginRef'].resetFields();
        }
      });
    },
    toResetPassword() {
      this.loginIsShow = false;
      this.registerIsShow = false;
      this.forgetIsShow = true;
      console.log(this.$refs);

      this.$refs['registerRef'].resetFields();
      this.$refs['loginRef'].resetFields();
    },
    signup() {
      toSignup({
        code: this.userInfo.authCode,
        confirmPassword: this.userInfo.password,
        email: this.userInfo.email,
        password: this.userInfo.prePassword,
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.$message({ message: 'succeed!', type: 'success' });
          this.loginIsShow = true;
          this.registerIsShow = false;
          this.forgetIsShow = false;
          this.$refs['registerRef'].resetFields();
        }
      });
    },
    toSignup() {
      this.loginIsShow = false;
      this.registerIsShow = true;
      this.forgetIsShow = false;
      this.$refs['registerRef'].resetFields();
      this.$refs['loginRef'].resetFields();
    },
    login() {
      this.$refs['loginRef'].validate((valid) => {
        if (valid) {
          this.$refs['loginRef'].clearValidate(this.$refs['loginRef']);
          toLogin({
            username: this.formLogin.email,
            password: this.formLogin.password,
          }).then((res) => {
            if (res.code === 200) {
              this.$message({ message: 'Login succeed!', type: 'success' });
              localStorage.setItem('token', res.data.token);
              localStorage.setItem('email', this.formLogin.email);
              this.$store.commit('SET_TOKEN', res.data.token)
              this.$refs['loginRef'].resetFields();
              this.$router.push({
                path: window.localStorage.getItem('fromPath') || '/home',
              });
            } else {
              this.$message({
                message: 'Password mailbox does not match, please re-ente',
                type: 'error',
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    toLogin() {
      this.loginIsShow = true;
      this.registerIsShow = false;
      this.forgetIsShow = false;
      this.$refs['registerRef'].resetFields();
    },
    onsubmit() {
      if (this.loginIsShow) {
        this.login();
      } else {
        this.$refs['registerRef'].validate((valid) => {
          if (valid) {
            if (this.registerIsShow) {
              console.log('5555');
              this.signup();
            } else if (this.forgetIsShow) {
              this.resetPassword();
            }
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  max-height: 1134px;
  min-height: 880px;
  background: url(../assets/images/loginbg.png) no-repeat;
  background-size: 100% 100%;
  padding-top: 68px;
  padding-bottom: 174px;

  .login-box {
    margin: auto;
    padding: 73px 76px;
    width: 599px;
    background: #f5f9ff;
    box-shadow: 0px 22px 42px 0px rgba(210, 210, 210, 0.4);
    opacity: 0.8;
    border-radius: 12px;

    .title {
      font-size: 32px;
      font-weight: 400;
      color: #050505;
      line-height: 44px;
      margin-bottom: 42px;
      margin-left: 35px;
    }

    /deep/ .el-form-item {
      margin-bottom: 24px;
    }

    /deep/ .el-form-item__error {
      font-size: 16px;
      margin-left: 33px;
      margin-bottom: 12px;
    }

    /deep/ .el-form-item__label {
      margin-left: 33px;
      font-size: 27px;
      color: #acacac;
    }

    /deep/ .el-input__inner {
      padding-left: 33px;
      height: 73px;
      width: 446px;
      background: #ffffff;
      border: 2px solid #f5f5f5;
      border-radius: 36px;
      font-size: 27px;
      color: #acacac;
    }

    /deep/ .el-input__clear {
      font-size: 22px;
    }

    .submit {
      width: 446px;
      height: 73px;
      margin-top: 11px;
      padding: auto;
      background: #43328F;
      border-radius: 36px;
      font-size: 32px;
      font-weight: 400;
      color: #ffffff;
    }

    .button-box {
      margin-top: 30px;
      display: flex;
      justify-content: space-around;

      .register {
        font-size: 27px;
        font-weight: 400;
        color: #fab711;
      }

      .forget {
        font-size: 27px;
        font-weight: 400;
        color: #acacac;
      }
    }

    .authCode {
      .auth-btn {
        width: 192px;
        height: 72px;
        background: #43328F;
        border-radius: 36px;
        font-size: 27px;
        font-weight: 400;
        color: #ffffff;
        margin-left: 14px;
      }

      .auth-input {
        width: 240px;

        /deep/ .el-input__inner {
          width: 240px;
        }
      }
    }
  }
}
</style>